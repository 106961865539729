import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

export default class Contact extends Component {
  render() {
    return (
      <section id="contact" className="mt-0-3-rem">
        <div className="body width-100-per">
          <Container className="contact-container1-style">
            <Container>
              <Row>
                <Col xs="12" lg="8">
                  <div className="float-left ml-5-per">
                    <h1>my@greggii.com</h1>
                    <h1>+1(786)-877-6205</h1>
                  </div>
                </Col>
                <Col xs="12" lg="4">
                  <div>
                    <Row>
                      <Col xs="2" lg="2" md="2" className="mt-4">
                        <a
                          rel="noreferrer"
                          href="https://www.instagram.com/my_greggii/"
                          target={"_blank"}
                        >
                          <Image src="images/instagram.png" fluid />
                        </a>
                      </Col>
                      <Col xs="2" lg="2" md="2" className="mt-4">
                        <a
                          rel="noreferrer"
                          href="https://twitter.com/my_greggii"
                          target={"_blank"}
                        >
                          <Image src="images/twitter.png" fluid />
                        </a>
                      </Col>
                      <Col xs="2" lg="2" md="2" className="mt-4">
                        <a
                          rel="noreferrer"
                          href="https://facebook.com/My-By-Greggii-108853304959787/"
                          target={"_blank"}
                        >
                          <Image src="images/facebook.png" fluid />
                        </a>
                      </Col>
                      <Col xs="2" lg="2" md="2" className="mt-4">
                        <a
                          rel="noreferrer"
                          href="https://www.tiktok.com/@my_greggii?"
                          target={"_blank"}
                        >
                          <Image src="images/tik-tok.png" fluid />
                        </a>
                      </Col>
                      <Col xs="2" lg="2" className="mt-4">
                        <a
                          rel="noreferrer"
                          href="https://www.linkedin.com/in/my-by-greggii-727592228"
                          target={"_blank"}
                        >
                          <Image src="images/linkedin.png" fluid />
                        </a>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </div>
      </section>
    );
  }
}
