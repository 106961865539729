import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { isAndroid, isIOS, isDesktop } from "react-device-detect";
import SectionTitle from "./section-title/section-title.component";
import SectionBody from "./section-body/section-body.component";
import { APPLE_STORE_URL, GOOGLE_STORE_URL } from "../utils/constants";
import CustomLabel from "./shared/label/custom-label.component";

export default class About extends Component {
  onDownload = () => {
    var now = new Date().valueOf();
    setTimeout(function () {
      if (new Date().valueOf() - now > 100) {
        if (isAndroid) {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1";
        } else if (isIOS) {
          window.location.href =
            "https://apps.apple.com/us/app/my/id1603899001";
        } else if (isDesktop) {
          window.location.href = "#download2";
        }
      }
    }, 500);
    window.location = "myapp://";
  };

  render() {
    return (
      <Container style={{ width: "99%", marginTop: "2%" }} id="about">
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col xl={10} lg={10} md={12} sm={12} xs={12}>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                // className="scrollable-product-column"
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CustomLabel
                    style={{
                      fontSize: "3rem",
                      fontWeight: "600",
                    }}
                  >
                    About us
                  </CustomLabel>
                </div>
              </Col>

              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "4%" }}
              >
                <SectionTitle title="Who we are" />
              </Col>

              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  marginTop: "32px",
                }}
              >
                <Row>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="text-column"
                  >
                    <SectionBody>
                      Welcome to My…, your trusted partner for various products
                      and services starting from you home needs to your pet
                      needs and all other related products and services.
                    </SectionBody>
                    <SectionBody style={{ marginTop: "5%" }}>
                      We proudly serve our local community and members, offering
                      exclusive deals, discounts, and access to exciting events.
                      Whether it's virtual meetups, social gatherings, or
                      pet-friendly activities, we create opportunities for our
                      exclusive community members to connect and share
                      experiences.
                    </SectionBody>
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={0} xs={0}></Col>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="image-column"
                  >
                    <Image
                      src={"images/about-1.png"}
                      alt="Logo"
                      // className="logo-image"
                      style={{ objectFit: "contain", width: "100%" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "4%" }}
              >
                <SectionTitle title="What we offer" />
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  marginTop: "32px",
                }}
              >
                <Row>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="image-column"
                  >
                    <Image
                      src={"images/about-2.png"}
                      alt="Logo"
                      // className="logo-image"
                      style={{ objectFit: "contain", width: "90%" }}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={0} xs={0}></Col>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="text-column"
                  >
                    <SectionBody>
                      In addition to high-quality products, Including home
                      services, pet setting, dog walking, and expert advice. Our
                      dedicated team is always ready to answer your questions
                      and address your concerns.
                    </SectionBody>
                    <SectionBody style={{ marginTop: "5%" }}>
                      Need veterinary care? We can refer you to trusted partners
                      from our network of vet clinics, ensuring your pet gets
                      the best care possible.
                    </SectionBody>
                  </Col>
                </Row>
              </Col>
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "4%" }}
              >
                <SectionTitle title="The app" />
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  marginTop: "32px",
                }}
              >
                <Row>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="text-column"
                  >
                    <SectionBody>
                      Through our app, you can engage with our exclusive
                      community who share your passion and interest.
                    </SectionBody>
                    <SectionBody style={{ marginTop: "5%" }}>
                      Connect with like-minded members, join discussions, or
                      even offer your own products and services to our
                      community.
                    </SectionBody>
                    <div
                      style={{
                        marginTop: "5%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <a
                        href={GOOGLE_STORE_URL}
                        style={{ marginRight: "3%" }}
                        target="_blank"
                      >
                        <img src="./images/google-play.png" alt="" />
                      </a>
                      <a href={APPLE_STORE_URL} target="_blank">
                        <img src="./images/app-store.png" alt="" />
                      </a>
                    </div>
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={0} xs={0}></Col>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="image-column"
                  >
                    <Image
                      src={"images/about-3.png"}
                      alt="Logo"
                      // className="logo-image"
                      style={{ objectFit: "contain", width: "90%" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "4%" }}
              >
                <SectionTitle title="Get in touch" />
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  marginTop: "32px",
                }}
              >
                <Row>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="image-column"
                  >
                    <Image
                      src={"images/about-4.png"}
                      alt="Logo"
                      // className="logo-image"
                      style={{ objectFit: "contain", width: "90%" }}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={0} xs={0}></Col>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="text-column"
                  >
                    <SectionBody>
                      At My..., we are here to help you achieve your goals as an
                      exclusive member to meet your specific needs.
                    </SectionBody>
                    <SectionBody style={{ marginTop: "5%" }}>
                      Join us today and discover the many ways we can support
                      you, send us an email at{" "}
                      <SectionBody style={{ textDecoration: "underline" }}>
                        my@greggii.com
                      </SectionBody>{" "}
                      or give us a call at 1-786-877-6205!
                    </SectionBody>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}
