import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { isAndroid, isIOS, isDesktop } from "react-device-detect";
import { APPLE_STORE_URL, GOOGLE_STORE_URL } from "../utils/constants";
import CustomLabel from "./shared/label/custom-label.component";
import "./home-page-download.style.css";

export default class Download extends Component {
  onDownload = () => {
    var now = new Date().valueOf();
    setTimeout(function () {
      if (new Date().valueOf() - now > 100) {
        if (isAndroid) {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1";
        } else if (isIOS) {
          window.location.href =
            "https://apps.apple.com/us/app/my/id1603899001";
        } else if (isDesktop) {
          window.location.href = "#download2";
        }
      }
    }, 500);
    window.location = "myapp://";
  };

  checkAppAndroid = () => {
    var now = new Date().valueOf();
    setTimeout(function () {
      if (new Date().valueOf() - now > 100) {
        if (isAndroid) {
          window.open(
            "https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1"
          );
        } else if (isIOS) {
          window.open(
            "https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1"
          );
        } else if (isDesktop) {
          window.open(
            "https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1"
          );
        }
      }
    }, 500);
    window.location = "myapp://";
  };

  checkAppApple = () => {
    var now = new Date().valueOf();
    setTimeout(function () {
      if (new Date().valueOf() - now > 100) {
        if (isAndroid) {
          window.open("https://apps.apple.com/us/app/my/id1603899001");
        } else if (isIOS) {
          window.open("https://apps.apple.com/us/app/my/id1603899001");
        } else if (isDesktop) {
          window.open("https://apps.apple.com/us/app/my/id1603899001");
        }
      }
    }, 500);
    window.location = "myapp://";
  };

  render() {
    return (
      <div style={{ marginTop: "5%", marginBottom: "3%" }} id="download">
        <Row style={{ width: "100%" }}>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                className="background-image"
                src={"./images/iPhone 16.svg"}
                alt="mobile image"
              />
            </div>
          </Col>
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="download-section-2"
            style={{ marginBottom: "3%" }}
          >
            <div className="download-section-3">
              <img
                src="images/about-1.png"
                alt="logo"
                style={{ width: "10%" }}
              />
              <div>
                <CustomLabel style={{ fontSize: "24px", fontWeight: "600" }}>
                  Download Free App!
                </CustomLabel>
              </div>
              <div>
                <CustomLabel> Available for iOS and Adroid. </CustomLabel>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "3%",
                }}
              >
                <a
                  href={GOOGLE_STORE_URL}
                  style={{ marginRight: "3%" }}
                  target="_blank"
                >
                  <img src="./images/google-play.png" alt="" />
                </a>
                <a href={APPLE_STORE_URL} target="_blank">
                  <img src="./images/app-store.png" alt="" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
